import React from "react"
import { FormattedMessage } from 'react-intl'

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThanksPage = ({ pathContext: { locale } }) => (
    <Layout locale={locale}>
        <SEO title="Kiitos"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1><FormattedMessage id="Thanks" /></h1>
                </div>

                <div className={"row"}>
                    <div className={"col-7"}>
                        <p><FormattedMessage id="ThanksMessage" /></p>
                    </div>

                    <div className={"col-5"}>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default ThanksPage
